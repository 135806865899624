import styled from "@emotion/styled";
import { TRANSPARENT_COLOR_NAME, UI_BORDER_LIGHT_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
import Colors from "styles/colors";
import { MD_DESKTOP_WIDTH } from "constants/vp-sizes";
export const CARD_WIDTH = 314;
export const CARD_HORIZONTAL_MARGIN = 8;
export const SMALLER_CARDS_WIDTH = 280;
export const StyledSelectableProductCard = styled.div`
  cursor: pointer;
  width: ${CARD_WIDTH}px;
  margin: 0 ${CARD_HORIZONTAL_MARGIN}px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  background-color: ${({ $cardBackgroundColor })=>$cardBackgroundColor ? colors[$cardBackgroundColor] : "inherit"};

  &:hover {
    .card-content {
      opacity: 1;
    }
  }

  .card-details {
    position: absolute;
    top: 0;
    left: 0;
    padding: ${({ $cardPadding })=>$cardPadding || "20px"};
    display: flex;
    flex-direction: column;
    gap: 4px;

    ${({ $spaceBetweenCardDetails })=>$spaceBetweenCardDetails && `
      width: 100%;
      height: 100%;
      justify-content: space-between;
    `}
    .tag-text-wrapper .first-paragraph .paragraph-body {
      font-size: 2.25rem;
      line-height: 42px;
      font-weight: 600;
      color: ${Colors.White};
    }
    .tag-text-wrapper .second-paragraph .paragraph-body {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 160%;
      margin-top: 12px;
      color: ${Colors.White};
    }

    .tag-text-wrapper {
      display: flex;
      flex-direction: column;
    }

    .product-icon {
      height: ${({ $cardDetailsProductIconHeight })=>$cardDetailsProductIconHeight || "36px"};
    }
  }

  .picture-wrapper {
    display: flex;
    justify-content: center;

    .picture-component {
      display: flex;

      img {
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  .card-content {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${({ $cardPadding })=>$cardPadding || "28px 20px 20px"};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: ${({ $cardBackgroundColor })=>$cardBackgroundColor ? colors[$cardBackgroundColor] : "rgba(0, 0, 0, 0.8)"};
    backdrop-filter: ${({ $cardBackgroundColor })=>$cardBackgroundColor ? "none" : "blur(2.5px)"};
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 0.875rem;
      color: ${({ $cardContentTextColor })=>$cardContentTextColor || colors[UI_BORDER_LIGHT_COLOR_NAME]};
    }

    .use-cases-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 12px;
      padding: 20px 0 32px;

      &.with-spacer {
        margin-top: auto;
      }

      .use-case {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 0.875rem;
        color: ${({ $cardContentTextColor })=>$cardContentTextColor || colors[UI_BORDER_LIGHT_COLOR_NAME]};
      }
    }

    .product-logo-wrapper {
      .picture-component {
        display: flex;

        img {
          height: ${({ $cardContentProductIconHeight })=>$cardContentProductIconHeight || "48px"};
        }
      }
    }

    .product-signup-wrapper {
      display: flex;
    }
  }

  @media (max-width: ${MD_DESKTOP_WIDTH}) {
    width: ${SMALLER_CARDS_WIDTH}px;
  }
`;
